import React from "react";
import "./About.scss";
import Animation2 from "../../Components/Animation2/Animation2";

const About = () => {
  return (
    <div className="about">
      <div className="about-background-animation">
        <Animation2 />
      </div>
      <div className="about-container">
        <h1>About Inferbyte Technologies</h1>
        <p>
          At Inferbyte Technologies, we are dedicated to harnessing the
          transformative power of artificial intelligence to drive innovation
          across industries. Our core mission is to create cutting-edge
          solutions that not only empower businesses but also enhance human
          capabilities. We firmly believe in the potential of technology to
          revolutionize lives, and we are committed to developing advanced
          applications that deliver tangible, real-world impact.
        </p>

        <p>
          Our vision is to be a trailblazer in the AI revolution, continually
          pushing the boundaries of machine learning, data science, and
          intelligent automation. We aspire to be at the forefront of
          technological advancements, making sophisticated AI tools accessible
          and practical for organizations of all sizes. By doing so, we aim to
          unlock new possibilities and drive substantial growth for our clients,
          contributing to their long-term success.
        </p>

        <p>
          We are committed to providing innovative, reliable, and scalable
          AI-based solutions tailored to meet the unique challenges and
          opportunities faced by our clients. Our mission extends beyond simply
          offering technology; we strive to drive significant value through our
          solutions, enhancing efficiency, productivity, and profitability. Our
          goal is to be a trusted partner, helping businesses navigate the
          complexities of the digital age with confidence and agility.
        </p>

        <p>
          At the heart of Inferbyte Technologies is a team of highly skilled
          professionals with diverse expertise in computer science, engineering,
          and business. Our collective knowledge and experience enable us to
          approach each project with a fresh perspective and a commitment to
          excellence. We work collaboratively, leveraging our strengths to
          deliver exceptional results and foster innovation. Our team’s
          dedication to continuous learning and growth ensures that we stay
          ahead of industry trends and maintain our position as leaders in AI
          technology.
        </p>

        <p>
          As we continue to evolve and expand, we remain focused on our core
          values of integrity, innovation, and excellence. We are driven by a
          passion for solving complex problems and delivering solutions that
          make a difference. Our commitment to these values shapes everything we
          do, from our strategic initiatives to our day-to-day operations,
          ensuring that we consistently deliver high-quality, impactful results
          for our clients.
        </p>

        <p>
          Join us on our journey to shape the future of technology. At Inferbyte
          Technologies, we are excited about the possibilities that lie ahead
          and are dedicated to making a meaningful contribution to the world
          through our innovative AI solutions.
        </p>

        {/* <div className="about-section">
          <h2>Meet Our Team</h2>
          <p>Our team is composed of experienced professionals with diverse backgrounds in computer science, engineering, and business. Together, we bring a wealth of knowledge and expertise to every project, working collaboratively to deliver exceptional results.</p>
        </div> */}
      </div>
    </div>
  );
};

export default About;
