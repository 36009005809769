import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './Article.scss';

const Article = () => {
  const { id } = useParams();
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const res = await fetch(`http://localhost:1337/api/articles/${id}`);
        if (!res.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await res.json();
        console.log('Article data:', data); // Debug output
        setArticle(data.data);
      } catch (error) {
        console.error('Error fetching article:', error);
        setArticle(null);
      } finally {
        setLoading(false);
      }
    };

    fetchArticle();
  }, [id]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!article) {
    return <p>Article not found</p>;
  }

  return (
    <div className="article-detail">
      <h1>{article.attributes.Title}</h1>
      <p><strong>Date:</strong> {article.attributes.Date}</p>
      <div>
        <p>{article.attributes.Paragraph1}</p>
        <p>{article.attributes.Paragraph2}</p>
        <p>{article.attributes.Paragraph3}</p>
        <p>{article.attributes.Paragraph4}</p>
      </div>
      {article.attributes.Media?.url && (
        <img src={article.attributes.Media.url} alt={article.attributes.Title} />
      )}
    </div>
  );
};

export default Article;
