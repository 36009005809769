// src/Pages/Insights/Insights.jsx
import React, { useState, useEffect } from 'react';
import './Insights.scss';

const Insights = () => {
  const [articles, setArticles] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMoreArticles, setHasMoreArticles] = useState(true);

  const fetchArticles = async (page = 1) => {
    setLoading(true);
    try {
      const res = await fetch(`http://localhost:1337/api/articles?_limit=10&_page=${page}`);
      if (!res.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await res.json();
      setArticles(data.data || []);
      setHasMoreArticles(data.data.length === 10); // Check if there might be more articles
    } catch (error) {
      console.error('Error fetching articles:', error);
      setArticles([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchArticles(page);
  }, [page]);

  const openArticle = (article) => {
    window.open(`/article/${article.id}`, '_blank');
  };
  

  return (
    <div className="insights-page">
      {loading ? (
        <p>Loading...</p>
      ) : articles.length > 0 ? (
        <ul className="articles-list">
          {articles.map(article => (
            <li key={article.id} className="article-item">
              <a onClick={() => openArticle(article)} className="article-link">
                <h3>{article.attributes.Title || 'No Title'}</h3>
              </a>
            </li>
          ))}
        </ul>
      ) : (
        <div className="no-articles">
          <p>No published insights available</p>
        </div>
      )}

      <div className="pagination-controls">
        <button onClick={() => setPage(prev => Math.max(prev - 1, 1))} disabled={page === 1}>
          Previous
        </button>
        <button onClick={() => setPage(prev => prev + 1)} disabled={!hasMoreArticles}>
          Next
        </button>
      </div>
    </div>
  );
};

export default Insights;
