import React from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import Animation1 from "../../Components/Animation1/Animation1";
import "./Home.scss";

const Home = () => {
  return (
    <div className="home">
      <div className="banner">
        <div className="banner-gradient-horizontal"></div>
        <div className="banner-gradient-vertical"></div>
        <div className="banner-animation-1">
          <Animation1 />
        </div>
        <div className="banner-text">
          <h1>Innovating the Future with the Power of AI</h1>
          <p>
            Transforming business ideas into reality with cutting-edge AI
            software solutions.
          </p>
          <div className="banner-buttons">
            <Link to="/services" className="primary-button">
              Explore Our Services
            </Link>
            <Link to="/contact" className="secondary-button">
              Contact Us
            </Link>
          </div>
        </div>
      </div>

      <div className="home-sections">
        {/* Services Section */}
        <div className="section section1">
          <h2>Our Services</h2>
          <div className="services-grid">
            <div className="service-block">
              <h3>AI Software Development</h3>
              <p>
                Develop bespoke AI solutions tailored to meet specific business
                needs. Our AI software enhances operational efficiency and
                drives data-driven decision-making.
              </p>
            </div>
            <div className="service-block">
              <h3>Neural Network Training</h3>
              <p>
                Train neural networks to achieve high accuracy in complex tasks
                such as image and speech recognition, enabling smarter and more
                intuitive systems.
              </p>
            </div>
            <div className="service-block">
              <h3>Natural Language Processing (NLP)</h3>
              <p>
                Implement advanced NLP solutions for effective text analysis,
                sentiment analysis, summarization, and language translation,
                enhancing user interactions and insights.
              </p>
            </div>
            <div className="service-block">
              <h3>Deep Learning Solutions</h3>
              <p>
                Create deep learning models to identify patterns and make
                predictions, driving innovation in areas such as predictive
                analytics and automation.
              </p>
            </div>
            <div className="service-block">
              <h3>Predictive Analytics</h3>
              <p>
                Utilize predictive analytics to forecast trends, optimize
                business strategies, and make informed decisions based on
                historical data and statistical models.
              </p>
            </div>
            <div className="service-block">
              <h3>AI-Based Cybersecurity</h3>
              <p>
                Enhance digital security with AI-powered solutions that detect
                and respond to threats in real-time, safeguarding your business
                against cyber-attacks.
              </p>
            </div>
            <div className="service-block">
              <h3>Computer Vision</h3>
              <p>
                Develop computer vision solutions to interpret and analyze
                visual data, enabling applications in quality control,
                autonomous systems, and more.
              </p>
            </div>
            <div className="service-block">
              <h3>Reinforcement Learning</h3>
              <p>
                Design reinforcement learning systems that learn and adapt from
                their environment, optimizing decision-making and improving
                performance over time.
              </p>
            </div>
          </div>
        </div>

        <div className="section section2">
          <p>
            At INFERBYTE TECHNOLOGIES, we are committed to revolutionizing the
            software industry with advanced AI-driven solutions. Our team of
            experts brings years of experience and innovative thinking to
            deliver scalable software solutions that enhance business efficiency
            and drive growth.
          </p>
          <br />
          <p>
            Our mission is to empower businesses by integrating cutting-edge
            technology into their operations, fostering innovation, and ensuring
            sustainable success. From startup ventures to established
            enterprises, we provide tailored solutions that address unique
            challenges and support long-term goals.
          </p>
          <br />
          <p>
            We are here to assist you in achieving your business goals through
            our innovative AI solutions. Whether you have questions about our
            services or wish to schedule a meeting, our team is ready to help.
            Reach out to us through the contact form or book a meeting at your
            convenience.
          </p>
          <br />
          <div className="bottom-buttons">
            <Link to="/about" className="primary-button">
              About Us
            </Link>
            <Link to="/contact" className="secondary-button">
              Contact Us
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
