import React from 'react';
import './Loading.scss';

const Loading = () => {
  return (
    <div className="loading-container">
      <div id="dots">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
}

export default Loading;
