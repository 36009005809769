import React from 'react';
import './Footer.scss';

// You can replace the # with the actual links to your social media pages.
const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-column">
          <div className="image">
            <img src="../../logo.png" alt="Startup Logo" className="footer-logo" />
          </div>
          <p>Inferbyte Technologies Private Limited</p>
        </div>

        <div className="footer-column footer-links">
          <ul>
            <li><a href="/">Home</a></li>
            <li><a href="/about">About</a></li>
            <li><a href="/career">Career</a></li>
            <li><a href="/services">Our Services</a></li>
          </ul>
        </div>

        <div className="footer-column footer-links">
          <ul>
            <li><a href="/contact">Contact Us</a></li>
            <li><a href="/insights">Insights</a></li>
            <li><a href="/privacy">Privacy Policy</a></li>
            <li><a href="/terms">Terms and Conditions</a></li>
          </ul>
        </div>

        {/* Updated footer-contact section */}
        <div className="footer-column footer-contact">
          <div className="social-icons">
            <a href="https://www.x.com/inferbyte" target="_blank" rel="">
              <i className="fab fa-twitter"></i>
            </a>
            <a href="https://www.instagram.com/inferbytetechnologies" target="_blank" rel="">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="https://www.linkedin.com/company/104430953/" target="_blank" rel="">
              <i className="fab fa-linkedin"></i>
            </a>
            <a href="https://github.com/InferbyteTechnologies" target="_blank" rel="">
              <i className="fab fa-github"></i>
            </a>
          </div>
          <div className="footer-contact-button">
            <a href="/contact" className="contact-button">Contact Us</a>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <p>&copy; {currentYear} Inferbyte Technologies Private Limited. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
