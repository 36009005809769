import React, { useEffect } from 'react';
import './Animation1.scss';

const Animation1 = () => {
  useEffect(() => {
    const canvas = document.getElementById('neuralNetworkCanvas');
    const ctx = canvas.getContext('2d');
    
    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };
    resizeCanvas();
    window.addEventListener('resize', resizeCanvas);

    const isMobile = window.innerWidth < 800;
    const numDots = isMobile ? 20 : 100;
    const maxConnections = isMobile ? 6 : 10;
    const maxDistance = isMobile ? 80 : 100;
    
    const dots = [];
    const gradientColors = [
      { r: 0, g: 240, b: 255 },
      { r: 60, g: 0, b: 255 },
      { r: 130, g: 0, b: 240 },
      { r: 0, g: 140, b: 255 }
    ];

    const interpolateColor = (startColor, endColor, factor) => {
      const r = Math.round(startColor.r + (endColor.r - startColor.r) * factor);
      const g = Math.round(startColor.g + (endColor.g - startColor.g) * factor);
      const b = Math.round(startColor.b + (endColor.b - startColor.b) * factor);
      return `rgb(${r}, ${g}, ${b})`;
    };

    const createDot = () => {
      const colorIndex = Math.floor(Math.random() * gradientColors.length);
      const nextColorIndex = (colorIndex + 1) % gradientColors.length;
      const factor = Math.random();
      
      return {
        x: Math.random() * canvas.width,
        y: Math.random() * canvas.height,
        radius: 2 + Math.random() * 4,
        color: interpolateColor(gradientColors[colorIndex], gradientColors[nextColorIndex], factor),
        dx: Math.random() * 2 - 1,
        dy: Math.random() * 2 - 1
      };
    };

    const connectDots = (dot1, index) => {
      let connections = 0; // Track number of connections per dot
      for (let i = index + 1; i < dots.length && connections < maxConnections; i++) {
        const dot2 = dots[i];
        const distance = Math.hypot(dot1.x - dot2.x, dot1.y - dot2.y);
        
        if (distance < maxDistance) {
          const factor = distance / maxDistance;
          const colorIndex = Math.floor(Math.random() * gradientColors.length);
          const nextColorIndex = (colorIndex + 1) % gradientColors.length;
          const lineColor = interpolateColor(gradientColors[colorIndex], gradientColors[nextColorIndex], factor);

          ctx.strokeStyle = lineColor;
          ctx.lineWidth = 1;
          ctx.beginPath();
          ctx.moveTo(dot1.x, dot1.y);
          ctx.lineTo(dot2.x, dot2.y);
          ctx.stroke();

          connections++; // Increment connection count
        }
      }
    };

    let lastTime = 0;
    const frameRate = 30;
    const animate = (time) => {
      if (time - lastTime < 1000 / frameRate) {
        requestAnimationFrame(animate);
        return;
      }

      lastTime = time;
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      dots.forEach((dot) => {
        dot.x += dot.dx;
        dot.y += dot.dy;

        if (dot.x < 0 || dot.x > canvas.width) dot.dx *= -1;
        if (dot.y < 0 || dot.y > canvas.height) dot.dy *= -1;

        ctx.shadowBlur = 10;
        ctx.shadowColor = dot.color;
        ctx.fillStyle = dot.color;

        ctx.beginPath();
        ctx.arc(dot.x, dot.y, dot.radius, 0, Math.PI * 2, false);
        ctx.fill();
      });

      dots.forEach((dot, index) => {
        connectDots(dot, index); // Connect dots with limited connections on mobile
      });

      requestAnimationFrame(animate);
    };

    for (let i = 0; i < numDots; i++) {
      dots.push(createDot());
    }

    animate();

    return () => {
      window.cancelAnimationFrame(animate);
      window.removeEventListener('resize', resizeCanvas);
    };
  }, []);

  return (
    <div className="animation-background">
      <canvas id="neuralNetworkCanvas"></canvas>
    </div>
  );
};

export default Animation1;
