import React from "react";
import "./Services.scss";

const Services = () => {
  return (
    <div className="services">
      <div className="services-container">
        <h1>Our Services</h1>
        <p>
          Inferbyte Technologies offers a comprehensive suite of AI-based
          services designed to address the complex needs of modern businesses.
          Explore our offerings to see how we can help you achieve your goals
          with advanced technology.
        </p>

        <div className="service-list">
          <div className="service">
            <h2>Custom AI Software Development</h2>
            <p>
              We develop AI software solutions tailored to meet your specific
              business needs, enhancing efficiency and driving innovation.
            </p>
          </div>

          <div className="service">
            <h2>Neural Network Training</h2>
            <p>
              Our team excels in training neural networks for various
              applications, ensuring high accuracy and performance for tasks
              such as image and speech recognition.
            </p>
          </div>

          <div className="service">
            <h2>Natural Language Processing (NLP)</h2>
            <p>
              We specialize in NLP solutions for tasks like sentiment analysis,
              text summarization, and language translation, enhancing your
              interactions with text data.
            </p>
          </div>

          <div className="service">
            <h2>Deep Learning Solutions</h2>
            <p>
              Our deep learning expertise enables us to create models that can
              identify patterns and make predictions based on complex data sets.
            </p>
          </div>

          <div className="service">
            <h2>Predictive Analytics</h2>
            <p>
              We offer predictive analytics services to help you forecast future
              trends and make data-driven decisions with confidence.
            </p>
          </div>

          <div className="service">
            <h2>AI-Driven Automation</h2>
            <p>
              Automate repetitive tasks and streamline processes with our
              AI-driven automation solutions, increasing productivity and
              reducing operational costs.
            </p>
          </div>

          <div className="service">
            <h2>Computer Vision</h2>
            <p>
              Implement advanced computer vision systems to analyze and
              interpret visual information from the world, improving accuracy
              and decision-making.
            </p>
          </div>

          <div className="service">
            <h2>AI-Based Cybersecurity</h2>
            <p>
              Enhance your security posture with AI-based solutions that detect
              and respond to cyber threats in real-time, safeguarding your
              digital assets.
            </p>
          </div>
          <div className="service">
            <h2>AI Strategy Consulting</h2>
            <p>
              We provide strategic guidance on AI adoption and implementation,
              helping businesses align AI initiatives with their goals and
              maximize the return on their AI investments.
            </p>
          </div>
        </div>

        <div className="contact-button">
          <a href="/contact" className="btn">
            Contact Us
          </a>
        </div>
      </div>
    </div>
  );
};

export default Services;
