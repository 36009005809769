import React from 'react';
import './Career.scss';

const Career = () => {
  return (
    <div className="career">
      <div className="career-container">
        <h1>Careers at Inferbyte Technologies</h1>
        <p>We are not currently hiring for any positions. For any other queries, please contact us at contact@inferbyte.com.</p>

        <div className="button-container">
          <a href="/" className="btn home-btn">Home</a>
          <a href="/contact" className="btn contact-btn">Contact Us</a>
        </div>
      </div>
    </div>
    // <div className="career">
    //   <div className="career-container">
    //     <h1>Careers at Inferbyte Technologies</h1>
    //     <p>We are always looking for talented individuals who can drive innovation. Below are some positions we will be hiring for in the future.</p>

    //     <div className="job-listings">
    //       <div className="job">
    //         <h2>Full Stack MERN Developer</h2>
    //         <p>We are looking for developers with expertise in MongoDB, Express.js, React, and Node.js. You should have experience building and deploying scalable web applications.</p>
    //         <button>Currently Not Hiring</button>
    //       </div>

    //       <div className="job">
    //         <h2>DevOps Engineer</h2>
    //         <p>We need professionals skilled in CI/CD pipelines, cloud infrastructure (AWS, GCP), and Docker/Kubernetes. Help us ensure smooth deployments and optimize our infrastructure.</p>
    //         <button>Currently Not Hiring</button>
    //       </div>

    //       <div className="job">
    //         <h2>UI/UX Designer</h2>
    //         <p>We're looking for creative individuals with experience in user-centered design to craft engaging and functional interfaces for our products.</p>
    //         <button>Currently Not Hiring</button>
    //       </div>

    //       <div className="job">
    //         <h2>AI/ML Engineer</h2>
    //         <p>Join us to build innovative solutions leveraging AI/ML algorithms. Expertise in deep learning frameworks like TensorFlow or PyTorch is required.</p>
    //         <button>Currently Not Hiring</button>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default Career;
