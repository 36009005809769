import React from "react";
import "./Terms.scss";

const Terms = () => {
  return (
    <div className="terms">
      <h1>Terms and Conditions</h1>
      <p>
        Welcome to INFERBYTE TECHNOLOGIES PRIVATE LIMITED. By accessing and
        using our website and services, you agree to comply with and be bound by
        the following terms and conditions. Please read them carefully.
      </p>

      <h2>1. Acceptance of Terms</h2>
      <p>
        By using our services, you acknowledge that you have read, understood,
        and agree to these terms. If you do not agree to these terms, you may
        not use our services.
      </p>

      <h2>2. Use of Services</h2>
      <p>
        You agree to use our AI-powered software solutions and other services
        only for lawful purposes. You are prohibited from using our services in
        any way that could damage, disable, overburden, or impair our services,
        or interfere with any other party's use and enjoyment of our services.
      </p>

      <h2>3. Intellectual Property</h2>
      <p>
        All content, including but not limited to software, code, designs,
        graphics, text, logos, and trademarks, provided on this website are the
        exclusive property of INFERBYTE TECHNOLOGIES PRIVATE LIMITED.
        Unauthorized use, reproduction, or distribution of any material from
        this site is strictly prohibited.
      </p>

      <h2>4. Limitation of Liability</h2>
      <p>
        INFERBYTE TECHNOLOGIES PRIVATE LIMITED is not liable for any direct,
        indirect, incidental, special, consequential, or punitive damages
        arising from your use of our services. This includes, but is not limited
        to, damages for errors, omissions, interruptions, defects, delays in
        operation, or unauthorized access to user data.
      </p>

      <h2>5. User Accounts</h2>
      <p>
        If you create an account with us, you are responsible for maintaining
        the confidentiality of your account information and for all activities
        that occur under your account. You agree to notify us immediately of any
        unauthorized use of your account.
      </p>

      <h2>6. Modifications to the Services and Terms</h2>
      <p>
        We reserve the right to modify or discontinue, temporarily or
        permanently, our services or any part of our services without notice. We
        may also update these terms from time to time. Your continued use of the
        services after any such changes constitutes your acceptance of the new
        terms.
      </p>

      <h2>7. Governing Law</h2>
      <p>
        These terms and conditions are governed by and construed in accordance
        with the laws of India, without regard to its conflict of law
        principles. Any legal action or proceeding arising out of or related to
        these terms will be brought exclusively in the courts located in India,
        specifically in the jurisdiction of Delhi. By using our services, you
        consent to the jurisdiction of these courts and waive any objection to
        inconvenient forum.
      </p>

      <h2>8. Termination</h2>
      <p>
        We reserve the right to suspend or terminate your access to our services
        at any time, without notice, for conduct that we believe violates these
        terms, is harmful to other users of the services, or is otherwise
        illegal or harmful to INFERBYTE TECHNOLOGIES PRIVATE LIMITED.
      </p>

      <h2>9. Contact Us</h2>
      <p>
        If you have any questions, concerns, or feedback regarding these Terms
        and Conditions, please contact us contact@inferbyte.com.
      </p>
    </div>
  );
};

export default Terms;
