import React from 'react';
import './Privacy.scss';

const Privacy = () => {
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>
      <p>Welcome to INFERBYTE TECHNOLOGIES PRIVATE LIMITED. Your privacy is important to us, and we are committed to protecting your personal information. This Privacy Policy outlines how we handle your information when you use our AI-powered software solutions and related services.</p>
      
      <h2>1. Information We Collect</h2>
      <p>We collect various types of information to provide you with our AI-based services and improve our offerings:</p>
      <ul>
        <li><strong>Personal Information:</strong> Information you provide directly, such as your name, email address, phone number, and any other information you choose to provide when contacting us or using our services.</li>
        <li><strong>Usage Information:</strong> Data about how you interact with our website and services, including your IP address, browser type, pages visited, and the time and date of your visits.</li>
        <li><strong>Technical Information:</strong> Information about the device you use to access our services, including hardware model, operating system, and mobile network information.</li>
      </ul>
      
      <h2>2. How We Use Your Information</h2>
      <p>We use the information we collect for various purposes, including:</p>
      <ul>
        <li>To provide, operate, and maintain our AI-based software solutions and services.</li>
        <li>To enhance and improve our website and services based on user feedback and usage patterns.</li>
        <li>To communicate with you, including sending updates, newsletters, and other information related to our services.</li>
        <li>To respond to your inquiries, provide customer support, and resolve issues.</li>
        <li>To comply with legal obligations and protect our rights and interests.</li>
      </ul>
      
      <h2>3. Sharing Your Information</h2>
      <p>We do not sell or share your personal information with third parties, except in the following cases:</p>
      <ul>
        <li>To service providers and partners who assist us in delivering our services, subject to confidentiality agreements.</li>
        <li>To comply with legal obligations, enforce our terms of service, or protect the rights, property, or safety of INFERBYTE TECHNOLOGIES PRIVATE LIMITED, our users, or others.</li>
      </ul>
      
      <h2>4. Your Rights</h2>
      <p>You have the right to access, update, and delete your personal information held by us. You can also request information about how your data is used and request restrictions on its processing. To exercise your rights, please contact us at privacy@inferbyte.com.</p>
      
      <h2>5. Data Security</h2>
      <p>We implement security measures to protect your information from unauthorized access, disclosure, or alteration. However, no method of transmission over the internet or method of electronic storage is completely secure, so we cannot guarantee absolute security.</p>
      
      <h2>6. Changes to This Privacy Policy</h2>
      <p>We may update our Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any significant changes by posting the new policy on our website and indicating the effective date.</p>
      
      <h2>7. Contact Us</h2>
      <p>If you have any questions, concerns, or requests regarding our Privacy Policy or how we handle your information, please contact us at contact@inferbyte.com.</p>
    </div>
  );
};

export default Privacy;
