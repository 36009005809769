import React, { useEffect, useState } from 'react';
import './Animation2.scss';

const Animation2 = () => {
  const [numPoints, setNumPoints] = useState(100);

  useEffect(() => {
    const canvas = document.getElementById('animationCanvas');
    const ctx = canvas.getContext('2d');

    const updateCanvasSize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      // Adjust numPoints based on the smaller dimension of the canvas
      const minDimension = Math.min(canvas.width, canvas.height);
      setNumPoints(Math.floor(minDimension / 10)); // Adjust divisor to scale number of points
    };

    updateCanvasSize(); // Initialize canvas size and number of points

    window.addEventListener('resize', updateCanvasSize);

    const points = [];
    const maxConnections = 3;
    const gradientColors = [
      { r: 0, g: 240, b: 255 },
      { r: 240, g: 0, b: 255 },
      { r: 130, g: 0, b: 240 },
      { r: 0, g: 140, b: 255 },
    ];

    const interpolateColor = (startColor, endColor, factor) => {
      const r = Math.round(startColor.r + (endColor.r - startColor.r) * factor);
      const g = Math.round(startColor.g + (endColor.g - startColor.g) * factor);
      const b = Math.round(startColor.b + (endColor.b - startColor.b) * factor);
      return `rgb(${r}, ${g}, ${b})`;
    };

    const createPoint = () => {
      const colorIndex = Math.floor(Math.random() * gradientColors.length);
      const nextColorIndex = (colorIndex + 1) % gradientColors.length;
      const factor = Math.random();
      return {
        x: Math.random() * canvas.width,
        y: Math.random() * canvas.height,
        radius: 5 + Math.random() * 5,
        color: interpolateColor(gradientColors[colorIndex], gradientColors[nextColorIndex], factor),
        dx: Math.random() * 2 - 1,
        dy: Math.random() * 2 - 1,
        brightness: Math.random() * 0.5 + 0.5,
      };
    };

    const distance = (dot1, dot2) => Math.hypot(dot1.x - dot2.x, dot1.y - dot2.y);

    const drawCurve = (dot1, dot2) => {
      const controlPoint1 = { x: (dot1.x + dot2.x) / 2, y: dot1.y };
      const controlPoint2 = { x: (dot1.x + dot2.x) / 2, y: dot2.y };

      ctx.strokeStyle = dot1.color;
      ctx.lineWidth = 2;
      ctx.beginPath();
      ctx.moveTo(dot1.x, dot1.y);
      ctx.bezierCurveTo(controlPoint1.x, controlPoint1.y, controlPoint2.x, controlPoint2.y, dot2.x, dot2.y);
      ctx.stroke();
    };

    const handleCollisions = () => {
      for (let i = 0; i < points.length; i++) {
        for (let j = i + 1; j < points.length; j++) {
          const point1 = points[i];
          const point2 = points[j];
          const dist = distance(point1, point2);
          if (dist < point1.radius + point2.radius) {
            // Adjust velocities
            point1.dx *= -1;
            point1.dy *= -1;
            point2.dx *= -1;
            point2.dy *= -1;
          }
        }
      }
    };

    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      handleCollisions();

      points.forEach((point) => {
        point.x += point.dx;
        point.y += point.dy;

        if (point.x < 0 || point.x > canvas.width) point.dx *= -1;
        if (point.y < 0 || point.y > canvas.height) point.dy *= -1;

        ctx.shadowBlur = 20;
        ctx.shadowColor = point.color;

        ctx.fillStyle = point.color;
        ctx.globalAlpha = point.brightness;
        ctx.beginPath();
        ctx.arc(point.x, point.y, point.radius, 0, Math.PI * 2, false);
        ctx.fill();
      });

      points.forEach((point) => {
        const connections = [];
        for (let i = 0; i < points.length; i++) {
          if (connections.length >= maxConnections) break;
          if (point === points[i]) continue;
          const otherPoint = points[i];
          const dist = distance(point, otherPoint);
          if (dist < 100) {
            connections.push(otherPoint);
            drawCurve(point, otherPoint);
          }
        }
      });

      requestAnimationFrame(animate);
    };

    const createPoints = () => {
      points.length = 0; // Clear existing points
      for (let i = 0; i < numPoints; i++) {
        points.push(createPoint());
      }
    };

    createPoints();
    animate();

    return () => {
      window.removeEventListener('resize', updateCanvasSize);
      window.cancelAnimationFrame(animate);
    };
  }, [numPoints]);

  return (
    <div className="animation-background">
      <canvas id="animationCanvas"></canvas>
    </div>
  );
};

export default Animation2;
