import React, { useState } from "react";
import "./Contact.scss";
import Animation1 from "../../Components/Animation1/Animation1";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });
  const [statusMessage, setStatusMessage] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [messageType, setMessageType] = useState(""); // 'success' or 'error'

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.name) errors.name = "Name is required.";
    if (!formData.email || !validateEmail(formData.email))
      errors.email = "Valid email is required.";
    if (!formData.message) errors.message = "Message is required.";
    if (formData.phone && !validatePhone(formData.phone))
      errors.phone =
        "Phone number can only contain digits, hyphens, and plus signs.";
    return errors;
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^[0-9-+]+$/;
    return phoneRegex.test(phone);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      try {
        const response = await fetch("https://api.web3forms.com/submit", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            access_key: "e8869a7a-7745-41e2-9e5a-ee1d440d7f28",
            ...formData,
          }),
        });
        const result = await response.json();
        if (result.success) {
          setStatusMessage("Message sent successfully!");
          setMessageType("success");
          setFormData({
            name: "",
            email: "",
            phone: "",
            subject: "",
            message: "",
          });
        } else {
          setStatusMessage("Failed to send message.");
          setMessageType("error");
        }
      } catch (error) {
        setStatusMessage("Error sending message.");
        setMessageType("error");
      }

      // Reset status message after 4 seconds
      setTimeout(() => {
        setStatusMessage("");
        setMessageType("");
      }, 4000);
    }
  };

  return (
    <div className="contact">
      <div className="contact-background">
        <div className="contact-back-gradient"></div>
        <Animation1 />
      </div>

      <div className="contact-form">
        <h1>Contact Us</h1>
        <form onSubmit={handleSubmit}>
          <label>
            Name (required)
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className={formErrors.name ? "error" : ""}
            />
            {formErrors.name && (
              <span className="error-message">{formErrors.name}</span>
            )}
          </label>
          <label>
            Email (required)
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className={formErrors.email ? "error" : ""}
            />
            {formErrors.email && (
              <span className="error-message">{formErrors.email}</span>
            )}
          </label>
          <label>
            Phone Number
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className={formErrors.phone ? "error" : ""}
            />
            {formErrors.phone && (
              <span className="error-message">{formErrors.phone}</span>
            )}
          </label>
          <label>
            Subject:
            <input
              type="text"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
            />
          </label>
          <label>
            Message (required)
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              className={formErrors.message ? "error" : ""}
            />
            {formErrors.message && (
              <span className="error-message">{formErrors.message}</span>
            )}
          </label>
          <button
            type="submit"
            className={`contact-button ${
              statusMessage
                ? messageType === "success"
                  ? "success"
                  : "error"
                : ""
            }`}
          >
            {statusMessage || "Send"}
          </button>
        </form>
        <div className="social-media">
          <a
            href="https://www.linkedin.com/company/inferbyte"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-linkedin"></i>
          </a>
          <a
            href="https://twitter.com/inferbyte"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-twitter"></i>
          </a>
          <a
            href="https://www.instagram.com/inferbytetechnologies"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-instagram"></i>
          </a>
          <a
            href="https://github.com/InferbyteTechnologies"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-github"></i>
          </a>
        </div>
        <div className="contact-para">
          <p>
            If you have any questions or need further information, please feel
            free to email us at
            <span className="email"> contact@inferbyte.com</span>. We will do
            our best to respond to your inquiry as soon as possible, usually
            within 1-2 working days.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
